.reset-link {
    color: #0073e6;
    /* Adjust to your theme color */
    font-weight: bold;
    text-decoration: none;
    padding: 5px 0;
    display: inline-block;
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.reset-link:hover {
    color: #005bb5;
    /* Darker shade for hover */
    border-bottom: 2px solid #005bb5;
    /* Underline effect */
}

.reset-link:focus {
    outline: none;
    border-bottom: 2px solid #0073e6;
}
