.strava-button {
    background-color: #fc4c02; /* Strava orange */
    color: white; /* Text color */
    border: none; /* No border */
    padding: 10px 20px; /* Padding */
    font-size: 16px; /* Font size */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s; /* Transition for hover effect */
  }
  
  .strava-button:hover {
    background-color: #e03e02; /* Darker orange on hover */
  }

.strava-button-container {
    padding: 10px;
}
  